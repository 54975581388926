/* eslint-disable no-unused-vars */
import ApiClient from '../ApiClient';
import { NiceResponse } from '../dto/NiceResponse';
import UserAuthRequest from '../analytics/dto/DateRequest.dto';
import { UserAuthResponse } from './dto/UserAuthResponse.dto';
import { UserForgotRequest } from './dto/UserForgotRequest.dto';
import { UserMeResponse } from './dto/UserMeResponse.dto';
import UserRegistrRequest from './dto/UserRegistrRequest.dto';
import { UserRegistrResponse } from './dto/UserRegistrResponse.dto';

export class UserApi {
	constructor(private api: ApiClient) { }

	path = '/auth';

	async login(req: UserAuthRequest) {
		const login = await this.api.client.post<NiceResponse<UserAuthResponse>>(this.path + '/login', { ...req });
		return login.data;
	}

	async forgotPassword(req: UserForgotRequest) {
		const restore = await this.api.client.put<NiceResponse<null>>(this.path + '/forgot-password', { ...req });
		return restore.data;
	}

	async registration(req: UserRegistrRequest) {
		const restore = await this.api.client.post<NiceResponse<UserRegistrResponse>>(this.path + '/register', { ...req });
		return restore.data;
	}

	async me() {
		const reg = await this.api.client.get<NiceResponse<UserMeResponse>>('/user/info');
		return reg.data;
	}
}