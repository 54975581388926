export const getTitleName = (location: string): string => {
	if (location === '/') { return 'Вход | NAN'; }
	if (location === '/registration') { return 'Регистрация | NAN'; }
	if (location === '/forget-password') { return 'Востановление пароля | NAN'; }
	if (location === '/reset-password') { return 'Изменение пароля | NAN'; }

	if (location === '/profile') { return 'Профиль | NAN'; }
	if (location === '/dashboard') { return 'Дашборд | NAN'; }
	

	else { return 'NAN'; }
};